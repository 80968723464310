import type { StrapiBuilderLayoutData } from '@autobid/strapi-integration/typescript/strapi/Page'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'

export interface HeaderProps {
  data: StrapiBuilderLayoutData
  callbackUrl: string
}

export const usePageHeader = () => {
  const { authData } = useAutobidAuth()

  const openReasonOnAuthorize = (bidBlockedBtn: HTMLButtonElement) => {
    if (authData.value.authorizing === 'login' && bidBlockedBtn) {
      bidBlockedBtn.click()

      setTimeout(() => {
        if (bidBlockedBtn.dataset.open === 'true') {
          bidBlockedBtn.click()
        }
      }, 10000)
    }
  }

  return { openReasonOnAuthorize }
}

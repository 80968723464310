<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="profile?.profileState.blockReasonMessage"
    class="w-max max-w-[300px] whitespace-normal p-2"
    v-html="profile.profileState.blockReasonMessage"
  ></div>
</template>

<script lang="ts" setup>
import { useProfile } from '@autobid/ui/composables/useProfile'

const { data: profile } = useProfile()
</script>
